import { combineReducers } from './combineReducers';
import { globalReducer } from './global/global.reducer';


export const initialState: AppState = {
  data: {
    questions: [],
    menuEnabled: true,
    currentQuestion: 1,
    score: 0,
  }
};

export const reducers = combineReducers({
  data: globalReducer
});

export type AppState = ReturnType<typeof reducers>;
