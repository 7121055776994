import React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import { Question } from "../models/Question";

interface AidePopoverProps {
  dismiss: (data: any) => void;
  question: Question;
}

const AidePopover: React.FC<AidePopoverProps> = ({ dismiss, question }) => {
  const close = (f: any) => {
    dismiss({ message: "fermer" });
  };

  return (
    <>
      <div className="ion-padding">
        <p><div dangerouslySetInnerHTML={{ __html: question.aide }} /></p>
        <h3>{question.enteteAide}</h3>
        <ul>
          {question.lignesAide.map((ligneAide) => (
            <li key={ligneAide}>{ligneAide}</li>
          ))}
        </ul>
        <p>{question.piedAide}</p>
      </div>
      <IonButton onClick={() => close({ message: "closed" })}>
        <IonIcon slot="icon-only" icon={closeCircle} />
      </IonButton>
      {/* <IonList>
       <IonItem button onClick={() => close({ message: "close" })}>
          <IonLabel color="vert">Fermer</IonLabel>
        </IonItem>
      </IonList> */}
    </>
  );
};

export default AidePopover;
