import { createSelector } from 'reselect';

import { Question } from '../models/Question';

import { AppState } from './state';

export const getQuestions = (state: AppState, _?:any) => state.data.questions;
export const getQuestionId = (state: AppState, _?:any) => state.data.currentQuestion;
export const getScore = (state: AppState, _?:any) => state.data.score;
export const setScore = (state: AppState, _?:any) => state.data.score;

const getIdParam = (_state: AppState, props: any) => {
  return props.match.params['id'];
};

export const getQuestion = createSelector(getQuestions, getIdParam, (questions, id) => questions.find((x: Question) => x.id === id));


