import { GlobalActions } from './global.actions';
import { GlobalState } from './global.state';

export const globalReducer = (state: GlobalState, action: GlobalActions): GlobalState => {
  switch (action.type) {

    case 'set-data':
      return { ...state, ...action.data };

    case 'set-current-question':
      return { ...state, currentQuestion: action.currentQuestion }

    case 'set-score':
      const index = state.questions.findIndex(q => q.id === action.questionId); //finding index of the item
      const newArray = [...state.questions]; //making a new array
      newArray[index].score = action.score; //changing value in the new array
      return {
       ...state, //copying the original state
       questions: newArray, //reassigning questions to new array
      }
  }
}
