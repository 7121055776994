import React, { useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonImg,
  IonChip,
  IonThumbnail,
  IonIcon,
  IonButton,
} from "@ionic/react";
import QuestionItem from "../components/QuestionItem";
import { Question } from "../models/Question";
import { connect } from "../data/connect";
import * as selectors from "../data/selectors";
import "./QuestionList.scss";
import { warningOutline } from "ionicons/icons";

interface OwnProps {}

interface StateProps {
  questionId: number;
  questions: Question[];
}

interface DispatchProps {}

interface QuestionListProps extends OwnProps, StateProps, DispatchProps {}

const QuestionList: React.FC<QuestionListProps> = ({
  questions,
  questionId,
}) => {
  const [scoreTotal, setScoreTotal] = React.useState(0);
  useEffect(() => {
    setScoreTotal(questions.reduce((acc, curr) => acc + curr.score, 0));
  }, [questions]);
  //console.log("questionId", questionId);

  return (
    <IonPage id="question-list">
      <IonHeader translucent={true}>
        <IonToolbar>
          {/* <IonButtons slot="icon-only">
          </IonButtons>   */}
          <IonThumbnail slot="start">
            <IonImg
              className="logo"
              src={`${process.env.PUBLIC_URL}/assets/img/logo-casa-horizon.jpg`}
              alt={`logo`}
            />
          </IonThumbnail>

          {/* {questionId < 28 ? (
            <>
              <IonTitle>
                Question {questionId}/{questions.length}
              </IonTitle>
            </>
          ) : (
            <>
              <IonTitle>Récapitulatif des points</IonTitle>
            </>
          )} */}
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={true}>
        <IonGrid fixed>
          <IonRow>
            {questions
              .filter((q) => q.id === questionId)
              .map((q) => (
                <IonCol size="12" size-md="6" key={`qu${q.id}`}>
                  <QuestionItem
                    key={`u${q.id}`}
                    question={q}
                    nbQuestions={questions.length}
                  />
                </IonCol>
              ))}
          </IonRow>
          <IonRow>
            <IonLabel>
              Vous cumulez jusqu’ici{" "}
              <IonChip color="primary"> {scoreTotal}</IonChip> points
            </IonLabel>

            {/* Vous cumulez jusqu’ici {scoreTotal} points */}
          </IonRow>
          <IonRow className="ion-justify-content-start">
            <p>
              Le nombre de points obtenus avec ce simulateur est indicatif. Il
              est basé sur vos déclarations.
            </p>
          </IonRow>

          {questionId === 28 && (
            <>
              <IonRow>
                <IonCol size="2">
                  <IonButton color="warning">
                    <IonIcon slot="icon-only" icon={warningOutline} />
                  </IonButton>
                </IonCol>
                <IonCol size="10">
                  <p>
                    Une note élevée ne signifie pas automatiquement que vous
                    recevrez une proposition de logement social très rapidement.
                    <br />
                    En revanche, il est probable que vous attendrez moins
                    longtemps que la moyenne.
                  </p>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="2">
                  <IonButton color="warning">
                    <IonIcon slot="icon-only" icon={warningOutline} />
                  </IonButton>
                </IonCol>
                <IonCol size="10">
                  <p>

                    Une note basse ne signifie pas que vous n’aurez jamais de
                    proposition.
                    <br />
                    En revanche, il est probable que vous attendrez plus
                    longtemps que la moyenne.
                  </p>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <ul>
                    <li>
                      Le délai d’attente dépend des opportunités : il faut qu’un
                      logement adapté à votre composition familiale et à vos
                      revenus se libère ou se construise.
                    </li>
                    <li>
                      Il dépend aussi de la position de votre demande par
                      rapport à celle des autres demandeurs dans la même
                      situation que vous.
                    </li>
                  </ul>
                  <p>
                    Cette information apparaît sur le&nbsp;
                    <a
                      href="https://www.demande-logement-social.gouv.fr/index"
                      target="_new"
                    >
                      Portail grand public
                    </a>&nbsp;
                    lorsque vous déposez une demande de logement social. Pour
                    connaitre le délai moyen d’attente, veuillez vous rendre sur
                    le&nbsp;
                    <a
                      href="https://www.demande-logement-social.gouv.fr/index"
                      target="_new"
                    >
                      Portail grand public.
                    </a>
                    <br /><br />
                  </p>
                </IonCol>
              </IonRow>
            </>
          )}

          {questionId === 28 &&
            questions.map((q) => (
              <>
              <h1>Récapitulatif des points</h1>
                <IonRow key={`ir${q.id}`}>
                  <IonCol size="9">
                    <IonLabel>
                      Critère {q.id} - {q.critere} :
                    </IonLabel>
                  </IonCol>
                  <IonCol size="3">
                    {/* <IonLabel>{q.score} points</IonLabel> */}
                    <IonChip color={q.score > 0 ? "success" : "warning"}>
                      <IonLabel>{q.score}</IonLabel>
                    </IonChip>
                  </IonCol>
                </IonRow>
              </>
            ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    questionId: selectors.getQuestionId(state),
    questions: selectors.getQuestions(state),
    score: selectors.getScore(state),
  }),
  component: React.memo(QuestionList),
});
