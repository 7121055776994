import { getData, setCurrentQuestionData} from '../dataApi';
import { ActionType } from '../../util/types';
import { GlobalState } from './global.state';

export const loadData = () => async (dispatch: React.Dispatch<any>) => {
  const data = await getData();
  dispatch(setData(data));
}

export const setData = (data: Partial<GlobalState>) => ({
  type: 'set-data',
  data
} as const);

export const setCurrentQuestion = (currentQuestion: number) => async (dispatch: React.Dispatch<any>) => {
  await setCurrentQuestionData(currentQuestion);
  return ({
    type: 'set-current-question',
    currentQuestion
  } as const);
}
export const setScore = (questionId:number, score: number) => {
  return ({
    type: 'set-score',
    questionId,
    score
  } as const);
}

export type GlobalActions =
  | ActionType<typeof setData>
  | ActionType<typeof setCurrentQuestion>
  | ActionType<typeof setScore>
