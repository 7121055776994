import React, { useEffect, useState } from "react";
import { Question } from "../models/Question";
import {
  IonCard,
  IonCardHeader,
  IonItem,
  IonLabel,
  IonAvatar,
  IonCardContent,
  IonText,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonChip,
  IonToggle,
  IonPopover,
  IonTitle,
  IonInput,
} from "@ionic/react";
import {
  arrowRedoCircleOutline,
  arrowUndoCircleOutline,
  business,
  calculator,
  helpCircle,
  hourglass,
  people,
  trailSign,
  trendingDown,
  warning,
  location,
} from "ionicons/icons";
import { setCurrentQuestion, setScore } from "../data/global/global.actions";
import { connect } from "../data/connect";
import AidePopover from "../components/AidePopover";

interface OwnProps {
  question: Question;
  nbQuestions: number;
}
interface StateProps { }

interface DispatchProps {
  setCurrentQuestion: typeof setCurrentQuestion;
  setScore: typeof setScore;
}

interface QuestionItemProps extends OwnProps, StateProps, DispatchProps { }

const QuestionItem: React.FC<QuestionItemProps> = ({
  question,
  nbQuestions,
  setCurrentQuestion,
  setScore,
}) => {
  const [coched, setCoched] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString()); // '2012-12-15T13:47:20.789'
  const [showAidePopover, setShowAidePopover] = useState(false);
  const [monthScore, setMonthScore] = useState(0);
  const [day, setDay] = useState<number>(new Date().getDate());
  const [month, setMonth] = useState<number>(new Date().getMonth() + 1);
  const [year, setYear] = useState<number>(new Date().getFullYear());

  const aidePopover = (e: React.MouseEvent) => {
    setShowAidePopover(true);
  };
  useEffect(() => {
    let now: Date = new Date();
    let sDate: Date = new Date(selectedDate);
    let months = (now.getFullYear() - sDate.getFullYear()) * 12;
    months -= sDate.getMonth();
    months += now.getMonth();
    if (now.getDate() < sDate.getDate()) {
      months--;
    }
    //setDay(now.getDate());
    if (months < 12) {
      setMonthScore(0);
      //setScore(question.id, 0);
    } else if (months < 25) {
      setMonthScore(10);
      //setScore(question.id, 20);
    } else if (months < 46) {
      setMonthScore(40);
      //setScore(question.id, 30);
    } else if (months < 58) {
      setMonthScore(70);
      //setScore(question.id, 50);
    } else if (months < 70) {
      setMonthScore(100);
      //setScore(question.id, 60);
    } else if (months < 82) {
      setMonthScore(130);
      //setScore(question.id, 70);
    } else if (months < 94) {
      setMonthScore(160);
      //setScore(question.id, 80);
    } else if (months < 106) {
      setMonthScore(190);
      //setScore(question.id, 90);
    } else if (months < 118) {
      setMonthScore(220);
      //setScore(question.id, 100);
    } else if (months > 117) {
      setMonthScore(250);
      //setScore(question.id, 110);
    }
    setScore(question.id, monthScore);
    // console.log(months);
  }, [monthScore, question.id, selectedDate, setScore]);
  useEffect(() => {
    if (!coched) {
      setScore(question.id, 0);
    } else {
      setScore(question.id, question.points);
    }
  }, [coched, question.id, question.points, setScore]);
  useEffect(() => {
    try {
      if(day && month && year) {
        if (day<1) setDay(1);
        if (day>31) setDay(31);
        if (month<1) setMonth(1);
        if (month>12) setMonth(12);
        //if (year<2000) setYear(2000);

        let now: Date = new Date();
        let currentYear:number = now.getFullYear();
        if (year>currentYear) {
          setYear(currentYear);
        }

        if ( year>=2000 && year<=currentYear && day > 0 && day < 32 && month > 0 && month <13) {
          setSelectedDate(new Date(year, month - 1, day).toISOString())
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [day, month, year, setDay,setMonth,setYear]);
  function suivant(question: Question) {
    let next = question.id + 1;
    setCurrentQuestion(next);
  }
  function precedent(question: Question) {
    let next = question.id - 1;
    setCurrentQuestion(next);
  }

  return (
    <>
      <img
        src={`${process.env.PUBLIC_URL}/assets/img/535px.png`}
        alt={`entete`}
      />< hr />
       {question.id === 1 && (
        <IonRow>
          <IonCol size="12"  >
<p>
        Vous envisagez de déposer une demande de logement social sur une ou plusieurs communes
        de la <a href='https://www.agglo-sophiaantipolis.fr/mon-agglo/1-agglo-1-technopole-24-communes/les-24-communes' target='_new'>Communauté d’Agglomération Sophia Antipolis</a>,
         et vous ne dépassez pas les <a href='https://www.agglo-sophiaantipolis.fr/fileadmin/medias/pdf/vivre_et_habiter/se_loger_et_habiter/2022/FICHES_LOGEMENTS_2022.pdf' target='_new'>plafonds de revenus du logement social.</a>
         <br /><br />
 Pour obtenir une simulation du nombre de points qui pourraient être associés à votre dossier, veuillez cocher les cases correspondant à votre situation.
 </p>
 </IonCol>
          </IonRow>
        )}
      <IonTitle>
        Question {question.id}/{nbQuestions}
      </IonTitle>
      <IonCard className="question-card">
        <IonCardHeader>
          <IonItem
            color="secondary"
            button
            detail={false}
            lines="none"
            className="question-item"
          >
            <IonAvatar slot="start">
              {/* <img
                src={process.env.PUBLIC_URL + question.questionImage}
                alt={`${question.id}`}
              />
              pour debug jump to 26 onClick={() => setCurrentQuestion(26)}
                */}
              <IonButton color="primary">
                {{
                  location: <IonIcon slot="icon-only" icon={location} />,
                  calculator: <IonIcon slot="icon-only" icon={calculator} />,
                  people: <IonIcon slot="icon-only" icon={people} />,
                  business: <IonIcon slot="icon-only" icon={business} />,
                  trailSign: <IonIcon slot="icon-only" icon={trailSign} />,
                  warning: <IonIcon slot="icon-only" icon={warning} />,
                  hourglass: <IonIcon slot="icon-only" icon={hourglass} />,
                  trendingDown: (
                    <IonIcon slot="icon-only" icon={trendingDown} />
                  ),
                }[question.icon] || (
                    <IonIcon slot="icon-only" icon={helpCircle} />
                  )}
              </IonButton>
            </IonAvatar>
            <IonText>
              <h3>
                <div
                  dangerouslySetInnerHTML={{ __html: question.section }}
                /></h3>
            </IonText>
          </IonItem>
        </IonCardHeader>

        <IonCardContent>
          <IonGrid>
            {question.id < 28 && (
              <>
                <IonRow className="ion-justify-content-start">
                  <IonCol size="10">
                    <div className="ion-padding">
                      <h2>
                        <div
                          dangerouslySetInnerHTML={{ __html: question.title }}
                        />
                      </h2>
                    </div>
                  </IonCol>
                  <IonCol size="2">
                    {(question.aide || question.enteteAide) && (
                      <IonButton onClick={aidePopover}>
                        <IonIcon slot="icon-only" icon={helpCircle} />
                      </IonButton>
                    )}
                  </IonCol>
                </IonRow>

                <IonRow className="ion-justify-content-start">
                  <IonCol size="12">
                    <IonItem lines="none">
                      <IonLabel>Non</IonLabel>
                      <IonToggle
                        checked={coched}
                        onClick={() => setCoched(!coched)}
                        color="primary"
                      ></IonToggle>
                      <IonLabel>Oui</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>{coched && (
                  <IonRow className="ion-justify-content-start">
                    <IonCol size="12">

                      <div className="ion-padding">
                        <h3>
                          <div
                            dangerouslySetInnerHTML={{ __html: question.entete }}
                          />
                        </h3>
                        <ul>
                          {question.docs.map((doc) => (
                            <li key={doc}>{doc}</li>
                          ))}
                        </ul>
                        <p>{question.pied}</p>
                      </div>
                    </IonCol>
                  </IonRow>)}
                {question.id === 26 && coched && (
                  <>
                    <IonRow className="ion-justify-content-start">
                      <IonCol size="2">
                        <p>Jour</p>
                      </IonCol>
                      <IonCol size="2">
                        <p>Mois</p>
                      </IonCol>
                      <IonCol size="2">
                        <p>Année</p>
                      </IonCol>
                      <IonCol size="6">
                        <IonLabel color="primary">
                          <h2>
                            <strong>
                              Date de la demande
                            </strong>
                          </h2>
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-start">
                      <IonCol size="2">
                        <IonInput type="number" min="1" max="31" value={day} placeholder="Jour" onIonChange={e => setDay(parseInt(e.detail.value!, 10))}></IonInput>
                      </IonCol>
                      <IonCol size="2">
                        <IonInput type="number" min="1" max="12" value={month} placeholder="Mois" onIonChange={e => setMonth(parseInt(e.detail.value!, 10))}></IonInput>
                      </IonCol>
                      <IonCol size="2">
                        <IonInput type="number" value={year} placeholder="Année" onIonChange={e => setYear(parseInt(e.detail.value!, 10))}></IonInput>
                      </IonCol>
                      <IonCol size="6">
                        <IonLabel color="primary">
                          <h2>
                            <strong>
                              {new Date(selectedDate).toLocaleDateString()}
                            </strong>
                          </h2>
                        </IonLabel>
                      </IonCol>
                    </IonRow>
                     {/*<IonRow className="ion-justify-content-start">
                      showDefaultButtons en
                      <IonCol size="12">
                        <IonDatetime
                          presentation="date"
                          locale="fr-FR"
                          firstDayOfWeek={1}
                          value={selectedDate}
                          onIonChange={(e) => setSelectedDate(e.detail.value!)}
                        ></IonDatetime>
                      </IonCol>
                    </IonRow>*/}
                  </>
                )}
                {coched && (
                  <IonRow className="ion-justify-content-start">
                    <IonCol size="8">
                      <IonText>Vous obtenez</IonText>
                      {question.id === 26 ? (
                        <IonChip color={monthScore > 0 ? "success" : "warning"}>
                          <IonLabel>
                            {monthScore > 0 ? "+" : ""} {monthScore}
                          </IonLabel>
                        </IonChip>
                      ) : (
                        <IonChip
                          color={question.points > 0 ? "success" : "warning"}
                        >
                          <IonLabel>
                            {question.points > 0 ? "+" : ""} {question.points}
                          </IonLabel>
                        </IonChip>
                      )}

                      <IonText>points</IonText>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow className="ion-justify-content-start">
                  <IonCol size="4">
                    {question.id > 1 && (
                      <IonButton
                        color="light"
                        onClick={() => precedent(question)}
                      >
                        <IonLabel></IonLabel>
                        <IonIcon
                          slot="icon-only"
                          icon={arrowUndoCircleOutline}
                        ></IonIcon>
                      </IonButton>
                    )}
                  </IonCol>
                  <IonCol size="4"></IonCol>
                  <IonCol size="4">
                    <IonButton
                      color="primary"
                      onClick={() => suivant(question)}
                    >
                      <IonLabel></IonLabel>
                      <IonIcon
                        slot="icon-only"
                        icon={arrowRedoCircleOutline}
                      ></IonIcon>
                    </IonButton>
                  </IonCol>
                </IonRow>
              </>
            )}
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonPopover
        id="aide-input-popover"
        isOpen={showAidePopover}
        showBackdrop={false}
        side="top"
        alignment="end"
        onDidDismiss={() => setShowAidePopover(false)}
      >
        <AidePopover
          question={question}
          dismiss={(data: any) => {
            // console.log(`dismiss  ${JSON.stringify(data)}`);
            setShowAidePopover(false);
          }}
        />
      </IonPopover>
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {
    setScore,
    setCurrentQuestion,
  },
  component: QuestionItem,
});
