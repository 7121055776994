import { Storage } from '@capacitor/storage';
import { Question } from '../models/Question';

const dataUrl = '/assets/data/data.json';

const QUESTION = 'question';
const SCORE = 'score';

export const getData = async () => {
  const response = await Promise.all([
    fetch(dataUrl),
    Storage.get({ key: QUESTION }),
  ]);
  const responseData = await response[0].json();
  const questions = responseData.questions as Question[];
  // const qid = await response[1].value || undefined;
  const currentQuestion = 1; //qid ? parseInt(qid) : 1;
  const data = {
    questions,
    currentQuestion
  }
  return data;
}

declare global {
  interface Window { socket: any; ws: any; }
}

export const setCurrentQuestionData = async (currentQuestion: number) => {
  await Storage.set({ key: QUESTION, value: JSON.stringify(currentQuestion) });
}

export const setScoreData = async (questionId:number, score: number) => {
  await Storage.set({ key: SCORE, value: JSON.stringify(score) });
}
